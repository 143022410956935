import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OnboardingService } from 'src/app/services/onboarding.service';

@Component({
  selector: 'app-document-uploaded',
  templateUrl: './document-uploaded.component.html',
  styleUrls: ['./document-uploaded.component.css'],
})
export class DocumentUploadedComponent {
  @Input() documents: any[];

  transactionId: string;
  reference: string;
  clientCode: string;
  agentEmail: string;
  hasD1NCD: boolean;
  hasD1LicenceFront: boolean;
  hasD2LicenceFront: boolean;
  hasD3LicenceFront: boolean;
  hasD4LicenceFront: boolean;
  hasD5LicenceFront: boolean;
  hasD6LicenceFront: boolean;
  hasD1LicenceBack: boolean;
  hasD2LicenceBack: boolean;
  hasD3LicenceBack: boolean;
  hasD4LicenceBack: boolean;
  hasD5LicenceBack: boolean;
  hasD6LicenceBack: boolean;
  pdfSrc: any;
  fileType: string;
  pdfViewerShowAll: boolean;
  pdfViewerZoom: 0.5;

  loadingPreview: boolean = false;
  loadingDownload: boolean = false;
  loadingDownloadNBPack: boolean = false;
  previewImage: any;
  previewCategory: string;
  previewRotate: number = 0;
  pdfRotateAngle: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private onBoardingService: OnboardingService
  ) {
    this.documents = data.documents;
    this.transactionId = data.id;
    this.agentEmail = data.agentEmail;
    this.reference = data.reference;
    this.clientCode = data.clientCode;

    this.documents.forEach((element) => {
      if (element.category === 'D1NCD' && element.uploaded)
        this.hasD1NCD = true;

      if (element.category === 'D1LicenceBack' && element.uploaded)
        this.hasD1LicenceBack = true;

      if (element.category === 'D1LicenceFront' && element.uploaded)
        this.hasD1LicenceFront = true;

      if (element.category === 'D2LicenceBack' && element.uploaded)
        this.hasD2LicenceBack = true;

      if (element.category === 'D2LicenceFront' && element.uploaded)
        this.hasD2LicenceFront = true;

      if (element.category === 'D3LicenceBack' && element.uploaded)
        this.hasD3LicenceBack = true;

      if (element.category === 'D3LicenceFront' && element.uploaded)
        this.hasD3LicenceFront = true;

      if (element.category === 'D4LicenceBack' && element.uploaded)
        this.hasD4LicenceBack = true;

      if (element.category === 'D4LicenceFront' && element.uploaded)
        this.hasD4LicenceFront = true;

      if (element.category === 'D5LicenceBack' && element.uploaded)
        this.hasD5LicenceBack = true;

      if (element.category === 'D5LicenceFront' && element.uploaded)
        this.hasD5LicenceFront = true;

      if (element.category === 'D6LicenceBack' && element.uploaded)
        this.hasD6LicenceBack = true;

      if (element.category === 'D6LicenceFront' && element.uploaded)
        this.hasD6LicenceFront = true;
    });
  }

  previewFile(category: string) {
    this.previewRotate = 0;
    this.pdfRotateAngle = 0;
    this.loadingPreview = true;
    this.previewImage = '';
    debugger;
    this.onBoardingService
      .downloadFile(this.transactionId, category, this.agentEmail)
      .subscribe((res) => {
        if (res.type === 'application/pdf') {
          this.fileType = res.type;
          //this.previewImage = '../../../assets/images/Icon_pdf_file.svg';

          var tempBlob = new Blob([res], { type: res.type });
          var reader = new FileReader();

          reader.onload = () => {
            this.pdfSrc = new Uint8Array(reader.result as ArrayBuffer);
          };

          reader.readAsArrayBuffer(tempBlob);
        } else {
          this.fileType = res.type;
          var reader = new FileReader();
          reader.readAsDataURL(res);

          reader.onload = (_event) => {
            this.previewImage = reader.result;
          };
        }

        this.previewCategory = category;
        this.loadingPreview = false;
      });
  }

  downloadFile(category: string) {
    this.loadingDownload = true;

    debugger;
    this.onBoardingService
      .downloadFile(this.transactionId, category, this.agentEmail)
      .subscribe((res) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(res);
        a.href = objectUrl;
        a.download = `${this.reference}_${category}`;
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.loadingDownload = false;
      });
  }

  downloadAllFiles() {
    this.loadingDownloadNBPack = true;
    this.onBoardingService
      .downloadAllFiles(this.transactionId, this.agentEmail)
      .subscribe((res) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(res);
        a.href = objectUrl;
        a.download = `MFQ_${this.clientCode}_NBPACK`;
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.loadingDownloadNBPack = false;
      });
  }

  rotateToRight() {
    this.previewRotate += 1;
    this.pdfRotateAngle += 90;
  }

  rotateToLeft() {
    this.previewRotate -= 1;
    this.pdfRotateAngle -= 90;
  }
}
