import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private http: HttpClient) {}

  downloadFile(
    transactionId: string,
    category: string,
    agentEmail: string
  ): Observable<Blob> {
    return this.http.post(
      `${environment.onBoardingAPI.address}/ob/v1/downloadfile?subscription-key=${environment.onBoardingAPI.subscriptionKey}`,
      { ID: transactionId, Category: category, AgentEmail: agentEmail },
      { responseType: 'blob' }
    );
  }

  downloadAllFiles(
    transactionId: string,
    agentEmail: string
  ): Observable<Blob> {
    return this.http.post(
      `${environment.onBoardingDocuments.address}/api/GetAllDocuments?code=${environment.onBoardingDocuments.code}`,
      { ID: transactionId, AgentEmail: agentEmail },
      { responseType: 'blob' }
    );
  }
}
