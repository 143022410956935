import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RssService } from 'src/app/services/rss.service';

@Component({
  selector: 'app-rss-document-uploaded',
  templateUrl: './rss-document-uploaded.component.html',
  styleUrls: ['./rss-document-uploaded.component.css']
})
export class RssDocumentUploadedComponent {
  @Input() uploadedFile: any;

  brokerName: string;
  application: string;
  policyCode: string;
  hasD1NCD: boolean;
  hasD1LicenceFront: boolean;
  hasD2LicenceFront: boolean;
  hasD3LicenceFront: boolean;
  hasD4LicenceFront: boolean;
  hasD5LicenceFront: boolean;
  hasD6LicenceFront: boolean;
  hasD1LicenceBack: boolean;
  hasD2LicenceBack: boolean;
  hasD3LicenceBack: boolean;
  hasD4LicenceBack: boolean;
  hasD5LicenceBack: boolean;
  hasD6LicenceBack: boolean;
  pdfSrc: any;
  fileType: string;
  pdfViewerShowAll: boolean;
  pdfViewerZoom: 0.5;

  loadingPreview: boolean = false;
  loadingDownload: boolean = false;
  loadingDownloadNBPack: boolean = false;
  previewImage: any;
  previewCategory: string;
  previewRotate: number = 0;
  pdfRotateAngle: number = 0;
   extractedData:any;
   ImageOpen: string;
   driverName:string;
   driveNumberpreview: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rssService: RssService
  ) {

    this.uploadedFile = data.validationLicences;
    this.application = data.application;
    this.policyCode = data.policyCode;
    this.brokerName = data.brokerName;
    this.extractedData = this.uploadedFile.map(Licences => {
      let fileName;
      if (Licences.licence.typeOfDoc == 'file' && Licences.licence?.imagesUrl.length > 0) {

        fileName = Licences.licence?.imagesUrl.map(li => {

          if (li === null) return "";
          
          const Name = li.split("/").pop();
          const Ext = Name.substring(0, Name.lastIndexOf(".")) || Name; // Remove extension dynamically
          const parts = Ext.split("_");
          return {
            side: parts[2],
            filePath: li
          };
        });

      }

      return {
        driverName: Licences.driverFirstName + ' ' + Licences.driverSurName,
        filePath: fileName,
        driverNumber: Licences.driverNumber,
        number: Licences.licence.number,
        typeOfDoc: Licences.licence.typeOfDoc
      };
    });
    console.log(this.extractedData);
    this.extractedData.forEach((element) => {
      if (element.driverNumber === '1' && element.side === 'back')
        this.hasD1LicenceBack = true;

      if (element.driverNumber === '1' && element.side === 'front')
        this.hasD1LicenceFront = true;

      if (element.driverNumber === '2' && element.side === 'back')
        this.hasD2LicenceBack = true;

      if (element.driverNumber === '2' && element.side === 'front')
        this.hasD2LicenceFront = true;

      if (element.driverNumber === '3' && element.side === 'back')
        this.hasD3LicenceBack = true;

      if (element.driverNumber === '3' && element.side === 'front')
        this.hasD3LicenceFront = true;

      if (element.driverNumber === '4' && element.side === 'back')
        this.hasD4LicenceBack = true;

      if (element.driverNumber === '4' && element.side === 'front')
        this.hasD4LicenceFront = true;

      if (element.driverNumber === '5' && element.side === 'back')
        this.hasD5LicenceBack = true;

      if (element.driverNumber === '5' && element.side === 'front')
        this.hasD5LicenceFront = true;

    });
  }
  previewDiverNumber(dNumber: string,dName: string){
    this.driveNumberpreview = dNumber;
    this.previewCategory = 'Driver Number'
    this.loadingPreview = false;
    this.driverName = 'Driver Name: '+dName;
  }
  previewFilePath(filePath: string, side: string,dName: string){
    this.driveNumberpreview  = '';
    this.previewRotate = 0;
    this.pdfRotateAngle = 0;
    this.loadingPreview = true;
    this.previewImage = '';
    this.driverName =  'Driver Name: '+dName;
    this.rssService
      .downloadFileByPath(filePath)
      .subscribe((res) => {
        if (res.type === 'application/pdf') {
          this.fileType = res.type;
          //this.previewImage = '../../../assets/images/Icon_pdf_file.svg';

          var tempBlob = new Blob([res], { type: res.type });
          var reader = new FileReader();

          reader.onload = () => {
            this.pdfSrc = new Uint8Array(reader.result as ArrayBuffer);
          };

          reader.readAsArrayBuffer(tempBlob);
        } else {
          // this.fileType = res.type;
          // var reader = new FileReader();
          // reader.readAsDataURL(res);
          this.previewImage = `data:${res.contentType};base64,${res.fileContents}`;
          // reader.onload = (_event) => {
          //   this.previewImage = reader.result;
          // };
        }
        this.driveNumberpreview = '';
        this.previewCategory = side;
        this.ImageOpen = filePath;
        this.loadingPreview = false;
      });
  }
  previewFile(driverNumber: string, side: string) {
    this.previewRotate = 0;
    this.pdfRotateAngle = 0;
    this.loadingPreview = true;
    this.previewImage = '';
    debugger;
    this.rssService
      .downloadFile(this.policyCode, driverNumber, side)
      .subscribe((res) => {
        if (res.type === 'application/pdf') {
          this.fileType = res.type;
          //this.previewImage = '../../../assets/images/Icon_pdf_file.svg';

          var tempBlob = new Blob([res], { type: res.type });
          var reader = new FileReader();

          reader.onload = () => {
            this.pdfSrc = new Uint8Array(reader.result as ArrayBuffer);
          };

          reader.readAsArrayBuffer(tempBlob);
        } else {
          // this.fileType = res.type;
          // var reader = new FileReader();
          // reader.readAsDataURL(res);
          this.previewImage = `data:${res.contentType};base64,${res.fileContents}`;
          // reader.onload = (_event) => {
          //   this.previewImage = reader.result;
          // };
        }

        this.previewCategory = '';
        this.loadingPreview = false;
      });
  }

  downloadFile(filePath: string) {
    this.loadingDownload = true;

    debugger;
    this.rssService
    .downloadFileByPath(filePath)
      .subscribe((res) => {
        const byteCharacters = atob(res.fileContents); 
        const byteNumbers = new Uint8Array(byteCharacters.length);
    
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
    
        const blob = new Blob([byteNumbers], { type: 'image/png' }); 
    
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = filePath.split("/").pop() || "download.png";
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
      this.loadingDownload = false;

  }

  downloadAllFiles() {
    this.loadingDownloadNBPack = true;
    // this.onBoardingService
    //   .downloadAllFiles(this.transactionId, this.agentEmail)
    //   .subscribe((res) => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(res);
    //     a.href = objectUrl;
    //     a.download = `MFQ_${this.clientCode}_NBPACK`;
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);
    //     this.loadingDownloadNBPack = false;
    //   });
  }

  rotateToRight() {
    this.previewRotate += 1;
    this.pdfRotateAngle += 90;
  }

  rotateToLeft() {
    this.previewRotate -= 1;
    this.pdfRotateAngle -= 90;
  }

}
