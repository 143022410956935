import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { AzureActiveDirectoryService } from 'src/app/azure-active-directory-service';
import { GeneralService } from 'src/app/services/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isUserLoggedIn: boolean = false;
  public user: any = null;
  private readonly _destroy = new Subject<void>();
  public urlGriad: string = 'MFQ';
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadCastService: MsalBroadcastService,
    private authService: MsalService,
    private azureADService: AzureActiveDirectoryService,
    private generalService: GeneralService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.router.events.subscribe((event: any) => {
      
      if(event.snapshot?.routeConfig?.path)
        this.urlGriad = event.snapshot.routeConfig.path;
      
    });

    this.msalBroadCastService.inProgress$
    .pipe(
      filter(
        (interactionStatus: InteractionStatus) =>
          interactionStatus == InteractionStatus.None
      ),
      takeUntil(this._destroy)
    )
    .subscribe((x) => {
      this.user = this.authService.instance.getAllAccounts()[0];
      this.isUserLoggedIn =
        this.authService.instance.getAllAccounts().length > 0;

      this.azureADService.isUserLoggedIn.next(this.isUserLoggedIn);
    });
  }
  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.postLogoutUrl,
    });
  }
}
