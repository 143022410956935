<!-- <app-navbar></app-navbar> -->

<mat-toolbar class="mat-elevation-z4" color="primary">

  <a class="title" style="padding-left: 30px">SalesOps {{urlGriad}}</a>

  <div class="router-button">
    <button class="button"  mat-raised-button  routerLink="HFQ" (click)="urlGriad = 'HFQ'">
      HFQ
    </button>
    <button class="button" mat-raised-button  routerLink="MFQ" (click)="urlGriad = 'MFQ'">
      MFQ
    </button>
  </div>
  <div class="toolbar-spacer"></div>

  <button mat-raised-button *ngIf="!isUserLoggedIn" (click)="login()">
    Login
  </button>

  <div class="username-container" *ngIf="isUserLoggedIn">
    Hello,
    <a class="username">
      {{ user?.name }}
    </a>

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Example home icon">
          account_circle
        </mat-icon>
        <span>{{ user?.username }}</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item>
        <mat-icon>tag</mat-icon>
        <span>3.1.0</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<router-outlet></router-outlet>