<mat-toolbar class="summary-info">
  <div class="router-button">
      
        <mat-spinner *ngIf="false"></mat-spinner>
  
        <button mat-raised-button color="primary" (click)="getTodaySales()">
          Today
        </button>
        <button mat-raised-button color="primary" (click)="exportCSV(SalesList)" aria-label="Download CSV">
          <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" aria-hidden="false"
          aria-label="Example home icon">
          download
        </mat-icon>
        </button>
       
      
    
    </div>
    <div class="toolbar-spacer"></div>
    <div >
      <mat-form-field appearance="legacy" style="padding-top: 29px; width: 100%" color="primary">
        <mat-label>Search</mat-label>
        <input matInput placeholder=" " autocomplete="off" (change)="searchChanged()" [(ngModel)]="searchedText" />
        <mat-icon matSuffix style="font-size: 1.5rem; vertical-align: bottom; cursor: pointer">search
        </mat-icon>
      </mat-form-field>
    </div>
  
    <div class="sales-date-range">
      <mat-form-field id="dateRange" appearance="legacy" style="padding-left: 30px; padding-top: 50px">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" [disabled]="disabled" />
          <input matEndDate formControlName="end" placeholder="End date" (dateChange)="dateRangeChanged()"
            [disabled]="disabled" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon style="font-size: 1.5rem; color: black">today</mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker [disabled]="!disabled"></mat-date-range-picker>
      </mat-form-field>
    </div>
  </mat-toolbar>
  
  <div class="content">
  
    <mat-card>
      <mat-card-title style="padding-left: 30px">
        {{ salesTotal }} Sales
        <span style="font-size: 1rem; color: #888b83">{{ startDate }} - {{ endDate }}</span></mat-card-title>
      <mat-card-content>
        <table mat-table [dataSource]="dataSource" style="width: 100%" matSort (matSortChange)="sortData($event)">
          <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->
  
          <!-- RAS Column 
          <ng-container matColumnDef="ras">
            <th mat-header-cell *matHeaderCellDef class="text-center">Save Quote</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <a (click)="createPolicy(element)">
                  <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                    aria-hidden="false">send</mat-icon>
                </a>
            </td>
          </ng-container>-->
  
          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="text-center">Status</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <div [ngSwitch]="element.status">
                <a *ngSwitchCase="1" (click)="openStatusUpdateDialog(element)">
                  <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                    matTooltip="Client code added/ Sale actioned" matTooltipPosition="after" class="green"
                    aria-hidden="false" aria-label="Example home icon">
                    circle
                  </mat-icon>
                </a>
  
                <a *ngSwitchCase="2" (click)="openStatusUpdateDialog(element)">
                  <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" matTooltip="Sent to NB agent"
                    matTooltipPosition="after" class="blue" aria-hidden="false" aria-label="Example home icon">
                    circle
                  </mat-icon>
                </a>
  
                <a *ngSwitchCase="3" (click)="openStatusUpdateDialog(element)">
                  <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                    matTooltip="Policy on cover/ need to complete on CPF portal" matTooltipPosition="after" class="yellow"
                    aria-hidden="false" aria-label="Example home icon">
                    circle
                  </mat-icon>
                </a>
  
                <a *ngSwitchCase="4" (click)="openStatusUpdateDialog(element)">
                  <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                    matTooltip="Renew offered, sent to renewal agent, will not be placed on cover as NB"
                    matTooltipPosition="after" class="purple" aria-hidden="false" aria-label="Example home icon">
                    circle
                  </mat-icon>
                </a>
  
                <a *ngSwitchCase="5" (click)="openStatusUpdateDialog(element)">
                  <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                    matTooltip="Error – sent to TL" matTooltipPosition="after" class="orange" aria-hidden="false"
                    aria-label="Example home icon">
                    circle
                  </mat-icon>
                </a>
  
                <a *ngSwitchCase="6" (click)="openStatusUpdateDialog(element)">
                  <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                    matTooltip="Error - sent to TL" matTooltipPosition="after" class="red" aria-hidden="false"
                    aria-label="Example home icon">
                    circle
                  </mat-icon>
                </a>
  
                <a *ngSwitchDefault (click)="openStatusUpdateDialog(element)">
                  <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" aria-hidden="false"
                    aria-label="Example home icon">
                    radio_button_unchecked
                  </mat-icon>
                </a>
              </div>
            </td>
          </ng-container>
  
          <!-- Client Code Column -->
          <ng-container matColumnDef="clientCode">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Policy Code
            </th>
            <td mat-cell aria-placeholder="Add client code" *matCellDef="let element" class="text-center">
  
              <!-- <a (click)="createPolicy(element)" *ngIf="!element.policyCode"><mat-icon matRipple
                  [matRippleCentered]="true" [matRippleUnbounded]="true" matTooltip="Add Policy Code"
                  matTooltipPosition="after" aria-hidden="false" aria-label="Example home icon">
                  person_add_alt
                </mat-icon>
              </a> -->
              <div *ngIf="element.policyCode">{{ element.policyCode }}</div>
              <mat-progress-bar class="policyCode-progress-bar" *ngIf="element.policyCodeBusy"
                mode="indeterminate"></mat-progress-bar>
            </td>
          </ng-container>
  
          <!-- Realex Ref ID Column -->
          <!-- <ng-container matColumnDef="realexReferenceID">
            <th mat-header-cell *matHeaderCellDef>
              Quote Ref
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.quoteCodeReference }}
            </td>
          </ng-container> -->
  
          <!-- Customer Name Column -->
          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Customer Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{
              element.payer.name +
              " " +
              element.payer.last_name
              }}
            </td>
          </ng-container>
  
          <!-- Date Sold Column -->
          <ng-container matColumnDef="dateSold">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Sold</th>
            <td mat-cell *matCellDef="let element">
              {{ element.payment.timeCreated | date : "dd/MM/yyyy h:mm a" }}
            </td>
          </ng-container>
  
          <!-- Insurer Column -->
          <ng-container matColumnDef="insurer">
            <th mat-header-cell *matHeaderCellDef>Insurer</th>
            <td mat-cell *matCellDef="let element">
              <div class="insurer-logo">
                <a>
                  <img matTooltip="{{ element.schemeCode }}" matTooltipPosition="before"
                    src="https://cdn.chill.ie/rss/img/{{ element.payment.offer.companyId }}.png" alt="{{ element.payment.offer.InsurerName }}" />
                </a>
              </div>
            </td>
          </ng-container>
  
          <!-- Price Column -->
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
            <td mat-cell *matCellDef="let element">
              <a matRipple [matRippleCentered]="true" (click)="openDiscountRssDetailsDialog(element)">
                {{ (element.payment.premiumAmount == 0
                ?element.payment.amount 
                : element.payment.premiumAmount 
                ) | currency : "EUR"
                }}
              </a>
            </td>
          </ng-container>
  
          <!-- Paid Amount Column -->
          <ng-container matColumnDef="paidAmount">
            <th mat-header-cell *matHeaderCellDef>Paid Amount</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{ isFullPayment: !element.payment.isFullPayment }">
              <a matRipple [matRippleCentered]="true" (click)="openDiscountRssDetailsDialog(element)">
                {{
                (element.payment.isFullPayment
                ? element.payment.amount
                : element.payment.depositAmount
                ) | currency : "EUR"
                }}
              </a>
              <!--BEFORE CHANGE 
                  {{
                  (element.payment.isFullPayment
                    ? element.quoteSummaries.displayedPremium
                    : element.quoteSummaries.finalDepositAmount
                  ) | currency : "EUR"
                }} -->
            </td>
          </ng-container>
          <!-- Key Care Column -->
          <ng-container matColumnDef="keycare">
            <th mat-header-cell *matHeaderCellDef style="padding-left: 20px; padding-right: 20px" class="text-center">
              Key Care
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <mat-icon *ngIf="element.payment.hasKeyCare" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" aria-hidden="false"
                aria-label="Example home icon">car_rental</mat-icon>
  
              <label *ngIf="!element.payment.hasKeyCare">{{
                "-"
                }}</label>
            </td>
          </ng-container>
          <!-- IBAN Column -->
          <ng-container matColumnDef="iban">
            <th mat-header-cell *matHeaderCellDef style="padding-left: 20px; padding-right: 20px" class="text-center">
              IBAN
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <!-- <a (click)="openIbanDialog(element)" *ngIf="element.iban">
                <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" aria-hidden="false"
                  aria-label="Example home icon">account_balance_wallet</mat-icon>
              </a> -->
  
              <label *ngIf="!element.iban">{{ "-" }}</label>
            </td>
          </ng-container>
  
          <!-- Inception Date Column -->
          <ng-container matColumnDef="inceptionDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Inception Date
            </th>
            <td mat-cell *matCellDef="let element">
              <span [ngClass]="
                    this.datepipe.transform(
                      element.payment.policyStartDate,
                      'dd/MM/yyyy'
                    ) === this.datepipe.transform(dateToday, 'dd/MM/yyyy')
                      ? 'blink-text red'
                      : this.datepipe.transform(
                          element.payment.policyStartDate,
                          'dd/MM/yyyy'
                        ) > this.datepipe.transform(dateToday, 'dd/MM/yyyy')
                      ? 'green'
                      : 'grey'
                  ">{{
                element.payment.policyStartDate | date : "dd/MM/yyyy"
                }}</span>
            </td>
          </ng-container>
  
          <!-- Document Upload Column -->
          <ng-container matColumnDef="documents">
            <th mat-header-cell *matHeaderCellDef style="padding-left: 20px; padding-right: 20px" class="text-center">
              Documents
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <a (click)="openDocumentDialog(element)" *ngIf="element.validationLicences?.length > 0">
                <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" aria-hidden="false"
                  aria-label="Example home icon">file_present</mat-icon>
              </a>
  
              <label *ngIf="element.validationLicences?.length === 0">{{ "-" }}</label>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <footer></footer>
        </table>
      </mat-card-content>
      <mat-card-footer>
        <div class="spinner" *ngIf="busy">
          <mat-spinner></mat-spinner>
        </div>
        <div class="message-no-data" *ngIf="!busy && salesTotal === 0">
          <span>No Record Found</span>
        </div>
        <div class="paginator-container">
          <mat-paginator [pageSizeOptions]="[15,25, 50, 100]" showFirstLastButtons aria-label="Select page">
          </mat-paginator>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
  
  <br />
  <!-- <ul *ngFor="let e of data">
    <li>{{e.name}}</li>
    <li>{{e.age}}</li>
    <li>{{e.country}}</li>
  </ul> -->
  