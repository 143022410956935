<mat-card class="sales-status-card">
  <mat-card-title>Status List</mat-card-title>
  <mat-card-subtitle
    >Select an option to update the sale's status</mat-card-subtitle
  >
  <mat-divider inset class="mb-20"></mat-divider>
  <mat-card-content>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      [ngModel]="selectedStatus"
    >
      <div>
        <mat-radio-button
          class="example-radio-button"
          value="10"
          (click)="updateStatus('10')"
        >
          <mat-icon aria-hidden="false" aria-label="Example home icon"
            >label_important_outline</mat-icon
          >
          Clear Status
        </mat-radio-button>
      </div>
      <mat-divider inset></mat-divider>

      <div>
        <mat-radio-button
          class="example-radio-button"
          value="1"
          (click)="updateStatus('1')"
        >
          <mat-icon
            aria-hidden="false"
            class="green"
            aria-label="Example home icon"
            >label_important</mat-icon
          >
          Client code added/ Sale actioned
        </mat-radio-button>
      </div>
      <mat-divider inset></mat-divider>

      <div>
        <mat-radio-button
          class="example-radio-button"
          value="2"
          (click)="updateStatus('2')"
        >
          <mat-icon
            aria-hidden="false"
            class="blue"
            aria-label="Example home icon"
            >label_important</mat-icon
          >
          Sent to NB agent
        </mat-radio-button>
      </div>
      <mat-divider inset></mat-divider>

      <div>
        <mat-radio-button
          class="example-radio-button"
          value="3"
          (click)="updateStatus('3')"
        >
          <mat-icon
            aria-hidden="false"
            class="yellow"
            aria-label="Example home icon"
            >label_important</mat-icon
          >
          Policy on cover/ need to complete on CPF portal
        </mat-radio-button>
      </div>
      <mat-divider inset></mat-divider>

      <div>
        <mat-radio-button
          class="example-radio-button"
          value="4"
          (click)="updateStatus('4')"
        >
          <mat-icon
            aria-hidden="false"
            class="purple"
            aria-label="Example home icon"
            >label_important</mat-icon
          >
          Renew offered, sent to renewal agent, will not be placed on cover as
          NB
        </mat-radio-button>
      </div>
      <mat-divider inset></mat-divider>

      <div>
        <mat-radio-button
          class="example-radio-button"
          value="5"
          (click)="updateStatus('5')"
        >
          <mat-icon
            aria-hidden="false"
            class="orange"
            aria-label="Example home icon"
            >label_important</mat-icon
          >
          Error – sent to TL
        </mat-radio-button>
      </div>
      <mat-divider inset></mat-divider>

      <div>
        <mat-radio-button
          class="example-radio-button"
          value="6"
          (click)="updateStatus('6')"
        >
          <mat-icon
            aria-hidden="false"
            class="red"
            aria-label="Example home icon"
            >label_important</mat-icon
          >
          Refund
        </mat-radio-button>
      </div>
      <mat-divider inset></mat-divider>
    </mat-radio-group>
  </mat-card-content>
</mat-card>
