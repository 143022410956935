import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { forkJoin, map, Observable, take, tap } from 'rxjs';
import { HFQDiscountDetailsComponent } from 'src/app/component/modal/template/sales/hfq-discount-details/hfq-discount-details.component';
import { RssDiscountDetailsComponent } from 'src/app/component/rss-discount-details/rss-discount-details.component';
import { StatusSalesComponent } from 'src/app/component/modal/template/sales/status-sales/status-sales.component';
import { RssDocumentUploadedComponent } from 'src/app/component/rss-document-uploaded/rss-document-uploaded.component';
import { SaleParameter } from 'src/app/model/RSS/SaleParameter.model';
import { Sales } from 'src/app/model/RSS/Sales.model';
import { RssService } from 'src/app/services/rss.service';
import { ngxCsv} from 'ngx-csv/ngx-csv';
// import { Transform } from 'stream';

@Component({
  selector: 'app-rss-dashboard-page',
  templateUrl: './rss-dashboard-page.component.html',
  styleUrls: ['./rss-dashboard-page.component.css']
})
export class RssDashboardPageComponent implements OnInit, AfterViewInit {
  user: AccountInfo;

  pageIndex: number = 0;
  pageSize: number = 100;
  length: number;

  disabled: boolean = true;
  public salesTotal: number = 0;
  public dataSource = new MatTableDataSource<Sales>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public sales$: Observable<Sales[]>;
  //public figures: SalesFigures;
  public dateToday: Date = new Date();
  public busy: boolean = false;
  public searchedText: string = '';
public SalesList: Sales[];
  startDate: any;
  endDate: any;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  public range = new UntypedFormGroup({
    start: new UntypedFormControl(new Date()),
    end: new UntypedFormControl(new Date()),
  });
  constructor(
    private rssService: RssService,
    private authService: MsalService,
    public dialog: MatDialog,
    public datepipe: DatePipe,) { }

  displayedColumns: string[] = [
    // 'ras',
    'status',
    'clientCode',
    // 'realexReferenceID',
    'customerName',
    'dateSold',
    'insurer',
    'price',
    'paidAmount',
    'keycare',
    // 'iban',
    'documents',
    'inceptionDate',
  ];
  ngOnInit(): void {
    console.log(this.authService.instance.getAllAccounts()[0]);
    this.listSales();
    this.user = this.authService.instance.getAllAccounts()[0];
  }
  title = "Export to CSV";
  
  exportCSV(a){
    // this.SalesList
    // let data = [
    //   {
    //     name: "john",
    //     age: 24,
    //     country: "USA"
    //   }
    // ]

    let dataFull = ({globalPaymentReference, saleDate, policyCode, payer, payment}) => 
      ({
        globalPaymentReference, 
        saleDate, 
        policyCode,
        payer_first_name: payer.first_name,
        payer_last_name: payer.last_name,
        payer_email: payer.email,
        payer_billing_address_city: payer.billing_address.city,
        payer_billing_address_country: payer.billing_address.country,
        payer_billing_address_line_1: payer.billing_address.line_1,
        payer_billing_address_line_2: payer.billing_address.line_2,
        payer_billing_address_postcode: payer.billing_address.postcode,
        payment_amount: payment.amount,
        payment_depositAmount: payment.depositAmount,
        payment_hasKeycare: payment.hasKeycare,
        payment_keyCareAmount: payment.keyCareAmount,
        payment_isFullPayment: payment.isFullPayment,
        payment_monthlyAmount: payment.monthlyAmount,
        payment_policyStartDate: payment.policyStartDate,
        payment_premiumAmount: payment.premiumAmount,
        payment_timeCreated: payment.timeCreated,
        payment_reference: payment.reference,
        offer_companyName: payment.offer.companyName,
        offer_insurerName: payment.offer.insurerName,
        offer_isCurrentOffer: payment.offer.isCurrentOffer,
        offer_payInFull: payment.offer.payInFull,
        offer_premium: payment.offer.premium,

      });
      let concatenatedLicenceNumbers = a
      .flatMap(item => item.validationLicences ? item.validationLicences.map(licence => licence.licence.number) : [])
      .join('');


      let concatenatedImg = a
      .flatMap(item => item.validationLicences ? item.validationLicences.map(licence => licence.licence.imagesUrl) : [])
      .join('');
      debugger
      let dataS = ({globalPaymentReference, saleDate, policyCode, payer, payment,status, validationLicences}) => 
        ({
          policyCode,
          StatusList: status,
          realexReferenceID: globalPaymentReference,
          dateSold: saleDate,
          timeSold: payment.timeCreated,
          product: 'Motor',
          offer: payment.offer.isCurrentOffer,
          Insurer: payment.offer.insurerName,
          price: payment.offer.premium,
          paidAmount: payment.amount,
          keyCare: payment.hasKeyCare,
          driverNumber: validationLicences?.map(a =>a.licence.typeOfDoc =='text').some(v => v === true),
          documents: validationLicences?.map(a =>a.licence.typeOfDoc =='file').some(v => v === true),
      
        });
        

      let result = a.map(dataS)
    
      let options = {
      title: 'A',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: false,
      useBom: false,
      noDownload: false,
      useHeader: true,
      headers: ['Policy Code', 'Status', 'Realex Reference ID', 'Date Sold',
         'Time Sold', 'Product', 'Offer', 'Insurer', 'Price', 'Paid Amount', 'Key Care', 'Driver Number', 'Documents'],
      
   
    nullToEmptyString: true,
    
    }
    let datepipe:DatePipe = new DatePipe('en-IE'); 

    // let fileName = this.datepipe.transform(new Date(), 'MM/dd/yy hh:mm'),

    new ngxCsv(result, 'RSS_Sales '+this.datepipe.transform(new Date(), 'dd/MM/yy hh:mm'), options);
    console.log(a)
  }
  dateRangeChanged() {
    // this.resetPaging();
    //this.searchedText = '';
    this.listSales();
  }


  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    debugger
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dateSold':
          return this.compare(a.payment.timeCreated, b.payment.timeCreated, isAsc);
        case 'customerName':
          return this.compare(a.payer.name, b.payer.name, isAsc);
        case 'price':
          return this.compare(a.payment.amount, b.payment.amount, isAsc);
        case 'inceptionDate':
          return this.compare(a.payment.policyStartDate, b.payment.policyStartDate, isAsc);
        // case 'protein':
        //   return compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  searchChanged() {
    // this.resetPaging();
    // if (this.searchedText !== '') {
    this.listSales();
    // }
  }

  getTodaySales() {
    this.range = new UntypedFormGroup({
      start: new UntypedFormControl(new Date()),
      end: new UntypedFormControl(new Date()),
    });

    this.listSales();
  }


  listSales() {
    let queryAditional;

    let saleParameter;
    if (
      this.range.controls['start'].value === null ||
      this.range.controls['end'].value === null
    ) {
    } else if (
      this.range.controls['start'].value !== null &&
      this.range.controls['end'].value !== null
    ) {
      this.dataSource.data = [];
      this.busy = true;
      saleParameter = new SaleParameter(
        this.datepipe.transform(
          this.range.controls['start'].value,
          'yyyy-MM-dd'
        ),
        this.datepipe.transform(this.range.controls['end'].value, 'yyyy-MM-dd'),
        this.searchedText,
        '01'
      );

      this.startDate = this.datepipe.transform(this.range.controls['start'].value, 'dd/MM/yyyy');

      this.endDate = this.datepipe.transform(this.range.controls['end'].value, 'dd/MM/yyyy');


      this.rssService
        .getSales(saleParameter)
        .pipe(
          tap((page) => {
           
            this.busy = false;
            this.salesTotal = page.saleTotal;
            this.dataSource.data = page.salesList;
            this.SalesList = page.salesList;
            console.log("this.SalesList: ",this.SalesList)
            // const updateRequests = page.salesList.map((sale) =>
            //   this.rssService.getListFile(sale.policyCode).pipe(
            //     map((extraData) => ({
            //       ...sale, 
            //       uploadedFile: extraData// Update hasUploadedFile 
            //     }))
            //   )
            // );


            // forkJoin(updateRequests).subscribe((updatedSales) => {
            //   this.dataSource.data = updatedSales; // Update the  data
              
            // });
          })
        )
        .subscribe({
          error: (error) => {
            this.busy = false;
            console.error(error);
          }
        });
    }
  }

  openStatusUpdateDialog(sale) {
    const dialogRef = this.dialog.open(StatusSalesComponent, {
      width: '100%',
      maxWidth: '700px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '400',
      data: { sale, username: this.user.username },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result.selectedStatus !== undefined) {
        sale.status = result.selectedStatus;
        this.saveSale(sale);
      }
    });
  }
  saveSale(sales: Sales) {
    this.rssService.saveSale(sales).subscribe({
      next: (res) => {
        console.log("Sale saved")
      },
      error: (error) => {
        console.log("error " + error)
      }
    });

  }
  openDiscountDetailsDialog(sale) {
    this.dialog.open(HFQDiscountDetailsComponent, {
      width: '100%',
      maxWidth: '1000px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '1000px',
      data: sale,
    });
  }
  openDiscountRssDetailsDialog(sale) {
    this.dialog.open(RssDiscountDetailsComponent, {
      width: '100%',
      maxWidth: '1000px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '1000px',
      data: sale,
    });
  }

  openDocumentDialog(sale) {
    console.log(sale);
    const dialogRef = this.dialog.open(RssDocumentUploadedComponent, {
      width: '100%',
      maxWidth: '1000px',
      height: 'auto',
      hasBackdrop: true,
      minHeight: '600px',
      data: {
        validationLicences: sale.validationLicences,
        policyCode: sale.policyCode,
        application:'RSS',
        brokerName:'Chill'
      },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }
}
