import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sales } from 'src/app/model/HFQ/Sales.model';

@Component({
  selector: 'app-hfq-discount-details',
  templateUrl: './hfq-discount-details.component.html',
  styleUrls: ['./hfq-discount-details.component.css']
})
export class HFQDiscountDetailsComponent implements OnInit {
  @Input() sales: Sales;

  public DamageCover: any;
  public paidAmountDataSource: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public saleParameter: Sales
  ) {
    this.sales = saleParameter;
    this.DamageCover = [
      {
        buildingsAccidentalDamageCoverIncluded: saleParameter.buildingsAccidentalDamageCoverIncluded,
        contentsAccidentalDamageCoverIncluded: saleParameter.contentsAccidentalDamageCoverIncluded,
      },
    ];
    debugger;
    this.paidAmountDataSource = [
      {
        displayAmount: saleParameter.payment.premiumAmount,
        depositAmount: saleParameter.payment.depositAmount,
        keyCareFee: '-',
        isKeyCareSelected:  false,
        isFullPayment: saleParameter.payment.isFullPayment
      },
    ];
  }

  ngOnInit(): void {}

  displayedColumns: string[] = [
    'buildingsAccidentalDamageCoverIncluded',
    'contentsAccidentalDamageCoverIncluded'
  ];

  paidAmountTableColumns: string[] = [
    'displayAmount',
    'depositAmount',
    'keyCareFee',
  ];
}
