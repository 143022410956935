import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardPageComponent } from './page/dashboard-page/dashboard-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { HfqDashboardPageComponent } from './page/hfq-dashboard-page/hfq-dashboard-page.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { RssDashboardPageComponent } from './page/rss-dashboard-page/rss-dashboard-page.component';

const routes: Routes = [
  {
    path: "",
    component: NavbarComponent,
    canActivate: [MsalGuard],
    children: [{
      path: "MFQ",
      component: DashboardPageComponent,
      canActivate: [MsalGuard],
    },
    {
      path: "HFQ",
      component: HfqDashboardPageComponent,
      canActivate: [MsalGuard],
    },
    {
      path: "RSS",
      component: RssDashboardPageComponent,
      canActivate: [MsalGuard],
    }
    ,{
      path: "",
      component: DashboardPageComponent,
      canActivate: [MsalGuard]
    }
    ]
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
