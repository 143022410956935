import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaleParameter } from '../model/HFQ/SaleParameter.model';
import { Observable } from 'rxjs';
import { SalesList } from '../model/RSS/SalesList.model';
import { environment } from 'src/environments/environment';
import { ListFilesParameter } from '../model/RSS/ListFilesParameter.model';
import { Sales } from '../model/RSS/Sales.model';

@Injectable({
  providedIn: 'root'
})
export class RssService {
  getExtraData(id: string) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }


  getSales(saleParameter: SaleParameter): Observable<SalesList> {
      // var functCode = environment.production ? 'OHyMgV1z9IqhBPqvXITdyMk6KRpn9SXPnR0OSgjNauC8ss4Wy40JMg==':'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==';
    //  var Param: any = {
    //    InitialDate: ReferenceCode,
     // };
    //  const headers = new HttpHeaders()
    //    .set('Content-Type', 'application/json')
     //   .set('x-functions-key', environment.rasfunctCode)
       // .set('Access-Control-Allow-Origin', '*');
  
      return this.http.post<SalesList>(
        environment.rssAPI.address + 'api/Sale/GetSales',
        saleParameter
      );
    }

    getListFile(transactionID: string): Observable<Array<string>> {
      // var functCode = environment.production ? 'OHyMgV1z9IqhBPqvXITdyMk6KRpn9SXPnR0OSgjNauC8ss4Wy40JMg==':'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==';
    //  var Param: any = {
    //    InitialDate: ReferenceCode,
     // };
    //  const headers = new HttpHeaders()
    //    .set('Content-Type', 'application/json')
     //   .set('x-functions-key', environment.rasfunctCode)
       // .set('Access-Control-Allow-Origin', '*');
       var  listFilesParameter: any = {Application:'MFQ',BrokerName:'Chill',TransactionID: transactionID}
      return this.http.post<Array<string>>(
        `${environment.rssAPI.uploadFunctionAPI}api/ListFiles?code=${environment.rssAPI.uploadFunctionAPICode}`,
        listFilesParameter
      );
    }

    downloadFile(policyCode: string,driverNumber: string, side: string): Observable<any> {
      // var functCode = environment.production ? 'OHyMgV1z9IqhBPqvXITdyMk6KRpn9SXPnR0OSgjNauC8ss4Wy40JMg==':'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==';
    //  var Param: any = {
    //    InitialDate: ReferenceCode,
     // };
    //  const headers = new HttpHeaders()
    //    .set('Content-Type', 'application/json')
     //   .set('x-functions-key', environment.rasfunctCode)
       // .set('Access-Control-Allow-Origin', '*');
       var  listFilesParameter: any = {Application:'RSS',BrokerName:'Chill',TransactionID: policyCode,DocSide:side, Identifier:driverNumber}
      return this.http.post<any>(
        `${environment.rssAPI.uploadFunctionAPI}api/GetLicenses?code=${environment.rssAPI.uploadFunctionAPICode}`,
        listFilesParameter
      );
    }
    downloadFileByPath(filePath: string): Observable<any> {
      // var functCode = environment.production ? 'OHyMgV1z9IqhBPqvXITdyMk6KRpn9SXPnR0OSgjNauC8ss4Wy40JMg==':'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==';
    //  var Param: any = {
    //    InitialDate: ReferenceCode,
     // };
    //  const headers = new HttpHeaders()
    //    .set('Content-Type', 'application/json')
     //   .set('x-functions-key', environment.rasfunctCode)
       // .set('Access-Control-Allow-Origin', '*'); 
       var  listFilesParameter: any = {filePath: filePath}
      return this.http.post<any>(
        `${environment.rssAPI.uploadFunctionAPI}api/GetLicensesByPath?code=${environment.rssAPI.uploadFunctionAPICode}`,
        listFilesParameter
      );
    }

      saveSale(sale: Sales): Observable<any>  {
        // var functCode = environment.production ? 'OHyMgV1z9IqhBPqvXITdyMk6KRpn9SXPnR0OSgjNauC8ss4Wy40JMg==':'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==';
      //  var Param: any = {
      //    InitialDate: ReferenceCode,
       // };
      //  const headers = new HttpHeaders()
      //    .set('Content-Type', 'application/json')
       //   .set('x-functions-key', environment.rasfunctCode)
         // .set('Access-Control-Allow-Origin', '*');
         debugger
         return this.http.post(
          environment.rssAPI.address + 'api/Sale/SaveSale',
          sale
        );
      }
}