import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type NewType = Subject<boolean>;

@Injectable({
  providedIn: 'root',
})
export class AzureActiveDirectoryService {
  isUserLoggedIn: NewType = new Subject<boolean>();
  constructor(private http: HttpClient) {}

  getMe(): Observable<any> {
    return this.http.get<any>('https://graph.microsoft.com/v1.0/me');
  }
}
