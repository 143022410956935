import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sales } from 'src/app/model/sales.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-discount-details',
  templateUrl: './discount-details.component.html',
  styleUrls: ['./discount-details.component.css'],
})
export class DiscountDetailsComponent implements OnInit {
  @Input() sales: Sales;

  public discounts: any;
  public paidAmountDataSource: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public saleParameter: Sales,
    private data: DataService
  ) {
    this.sales = saleParameter;
    this.discounts = [
      {
        PtCDiscount: saleParameter.quoteSummaries.ptcDiscount,
        TotalBrokerDiscount: saleParameter.quoteSummaries.totalBrokerDiscount,
        TotalInsurerDiscount: saleParameter.quoteSummaries.totalInsurerDiscount,
      },
    ];
    debugger;
    this.paidAmountDataSource = [
      {
        displayAmount: saleParameter.quoteSummaries.displayedPremium,
        depositAmount: saleParameter.payment.depositAmount,
        keyCareFee: saleParameter.quoteSummaries.keyCareFee,
        isKeyCareSelected:  saleParameter.quoteSummaries.isKeyCareSelected,
        isFullPayment: saleParameter.payment.isFullPayment
      },
    ];
  }

  ngOnInit(): void {}

  displayedColumns: string[] = [
    'ptcDiscount',
    'totalBrokerDiscount',
    'totalInsurerDiscount',
  ];

  paidAmountTableColumns: string[] = [
    'displayAmount',
    'depositAmount',
    'keyCareFee',
  ];
}
