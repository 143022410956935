<mat-card class="sales-status-card">
  <mat-card-title>Client Code Update</mat-card-title>
  <mat-card-subtitle
    >Update the client code and click the save button</mat-card-subtitle
  >
  <mat-divider inset class="mb-20"></mat-divider>
  <mat-card-content>
    <div>
      <mat-form-field class="client-code-form" appearance="standard">
        <mat-label>Client Code</mat-label>
        <input matInput type="text" [(ngModel)]="clientCode" />
        <button
          *ngIf="clientCode"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clientCode = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-divider inset></mat-divider>
  <mat-card-actions>
    <div class="text-right">
      <button (click)="updateClientCode()" mat-button>SAVE</button>
    </div>
  </mat-card-actions>
</mat-card>
