<mat-card class="sales-status-card">
  <mat-card-title>Paid Amount Breakdown</mat-card-title>
  <mat-divider inset></mat-divider>
  <mat-card-content>
    <div class="logo-insurer-discounts">
      <img
        src="assets/images/insurers/{{ sales.payment.insurerId }}.png"
        alt="{{ sales.quoteSummaries.insurer }}"
      />
      <span>{{ sales.quoteSummaries.insurer }}</span>
    </div>
    <mat-divider inset></mat-divider>
    <div class="discount-table">
      <table mat-table [dataSource]="discounts">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="ptcDiscount">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            PtC Discount
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{ element.PtCDiscount | currency : "EUR" }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="totalBrokerDiscount">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            Total Broker Discount
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{ element.TotalBrokerDiscount | currency : "EUR" }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="totalInsurerDiscount">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            Total Insurer Discount
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{ element.TotalInsurerDiscount | currency : "EUR" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <div class="paid-amount-container">
      <h3>Paid Amount</h3>
      <h2>{{ sales.payment.amount | currency : "EUR" }}</h2>

      <div class="paid-amount-table">
        <table mat-table [dataSource]="paidAmountDataSource">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="displayAmount">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Premium Amount
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.displayAmount | currency : "EUR" }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container  matColumnDef="depositAmount">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Deposit Amount
            </th>
            <td mat-cell *matCellDef="let element"  class="text-center">
              <label *ngIf="!element.isFullPayment">{{ element.depositAmount | currency : "EUR" }}</label>
              <label *ngIf="element.isFullPayment">{{ "-" }}</label>
            </td>
          </ng-container>

          <!-- Weight Column  -->
          <ng-container  matColumnDef="keyCareFee">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              KeyCare Fee
            </th>
            <td mat-cell  *matCellDef="let element"  class="text-center">
              <label *ngIf="element.isKeyCareSelected">  {{ element.keyCareFee | currency : "EUR" }}</label>
              <label *ngIf="!element.isKeyCareSelected">{{ "-" }}</label>
            </td>
            
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="paidAmountTableColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: paidAmountTableColumns"
          ></tr>
        </table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
