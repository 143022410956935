import { Payment } from './payment.model';
import { QuoteQueries } from './quoteQueries.model';
import { QuoteResponse } from './quoteResponse.model';
import { QuoteSummaries } from './quoteSummaries.model';
import { RelayData } from './relayData.model';

export class Sales {
  constructor(
    public clientCode: string,
    public status: string,

    public payment: Payment,
    public quoteSummaries: QuoteSummaries,
    public quoteResponse: QuoteResponse,
    public quoteQueries: QuoteQueries,
    public relayData: RelayData
  ) {}
}
