<mat-toolbar>
    <div class="header-img">
      <img
        src="https://cdn.chill.ie/rss/img/{{ sales.payment.offer.companyId }}.png"
        alt="{{ sales.payment.offer.companyName ?? sales.payment.offer.companyId }}"
      />
    </div>
    <div>
      <div class="header-title">
        {{ sales.payment.offer.insurerName }}
      </div>
      <div class="header-subtitle">
        {{ sales.payment.offer.companyName }}
      </div>
    </div>
    <span class="example-spacer"></span>
    <!-- <span>{{ sales.payment.displayOxara?.schemeCode ?? sales.schemeCode }}</span> -->
  </mat-toolbar>
  <mat-card class="sales-status-card">
    <!-- <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>{{
          sales.payment.displayOxara?.insurer ?? sales.schemeCode
        }}</mat-card-title>
        <mat-card-subtitle>{{
          sales.payment.displayOxara?.schemeCode ?? sales.schemeCode
        }}</mat-card-subtitle>
        <div mat-card-sm-image>
          <img
            src="https://cdn.chill.ie/hfq/img/{{ sales.schemeCode }}.png"
            alt="{{ sales.payment.displayOxara?.insurer ?? sales.schemeCode }}"
          />
        </div>
      </mat-card-title-group>
    </mat-card-header> -->
  
    <mat-card-title>Sale Breakdown</mat-card-title>
    <mat-divider inset></mat-divider>
    <!-- PERSONAL DATA -->
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Personal data </mat-panel-title>
          <mat-panel-description >
            {{ sales.payer.first_name+ ' ' + sales.payer.last_name | uppercase}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item>
            <mat-icon mat-list-icon>account_circle</mat-icon>
            <div mat-line>
              {{ sales.payer.first_name }} {{ sales.payer.last_name }}
              
            </div>
            <button mat-icon-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payer.first_name+ ' ' + sales.payer.last_name">
              <mat-icon class="small-icon">content_copy</mat-icon>
            </button>
          </mat-list-item>
          <mat-list-item >
            <mat-icon mat-list-icon>mail</mat-icon>
            <div mat-line>{{ sales.payer.email }}</div>
            <button mat-icon-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payer.email">
              <mat-icon class="small-icon">content_copy</mat-icon>
            </button>
          </mat-list-item>
          <mat-list-item>
            <mat-icon mat-list-icon>home_pin</mat-icon>
            <div mat-line>{{ sales.payer.billing_address.line_1 }}</div>
            <div mat-line>{{ sales.payer.billing_address.line_2 }}</div>
            <div mat-line>{{ sales.payer.billing_address.line_3 }}</div>
            <div mat-line>{{ sales.payer.billing_address.city }}</div>
            <div mat-line>{{ sales.payer.billing_address.country }}</div>
            <div mat-line>{{ sales.payer.billing_address.postal_code }}</div>
            <button mat-icon-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payer.billing_address.line_1+', '+sales.payer.billing_address.line_2+', '+sales.payer.billing_address.line_3+', '+sales.payer.billing_address.city+', '+sales.payer.billing_address.country+', '+sales.payer.billing_address.postal_code">
              <mat-icon class="small-icon">content_copy</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
  
      <!-- BENEFOTS -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Add Ons </mat-panel-title>
          <mat-panel-description>
            <!-- Type the country name -->
  
            <div>
              <!-- <mat-icon
                class="icon-benefit"
                [ngClass]="
                  sales.buildingsAccidentalDamageCoverIncluded ? 'green' : 'red'
                "
                >home_work</mat-icon> -->
  
              <!-- <mat-icon
                class="icon-benefit"
                [ngClass]="
                  sales.contentsAccidentalDamageCoverIncluded ? 'green' : 'red'
                "
                >diamond</mat-icon> -->

              <mat-icon class="icon-benefit" [ngClass]="sales.payment.hasKeyCare? 'green' : 'red'">key</mat-icon>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <mat-list>
          <!-- <div mat-subheader>Accidental Damage</div> -->
          <!-- <mat-list-item> -->
            <!-- <mat-icon
              *ngIf="!sales.buildingsAccidentalDamageCoverIncluded"
              mat-list-icon
              style="color: red"
              >cancel</mat-icon
            >
            <mat-icon
              *ngIf="sales.buildingsAccidentalDamageCoverIncluded"
              mat-list-icon
              style="color: green"
              >check_circle</mat-icon
            > -->
            <!-- <div mat-line>Buildings Accidental Damage</div> -->
          <!-- </mat-list-item> -->
          <!-- <mat-list-item> -->
            <!-- <mat-icon
              *ngIf="!sales.contentsAccidentalDamageCoverIncluded"
              mat-list-icon
              style="color: red"
              >cancel</mat-icon
            >
            <mat-icon
              *ngIf="sales.contentsAccidentalDamageCoverIncluded"
              mat-list-icon
              style="color: green"
              >check_circle</mat-icon
            > -->
            <!-- <div mat-line>Contents Accidental Damage</div> -->
          <!-- </mat-list-item> -->
          <mat-list-item>
            <mat-icon 
              *ngIf="!sales.payment.hasKeyCare" 
              mat-list-icon 
              style="color: red">cancel</mat-icon>
            <mat-icon
              *ngIf="sales.payment.hasKeyCare"
              mat-list-icon
              style="color: green"
              >check_circle</mat-icon
            >
            <div mat-line>KeyCare</div>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
      <!-- TRANSACTION -->
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> Payment </mat-panel-title>
          <mat-panel-description>
            {{ sales.payment.isFullPayment ? "FULL PAYMENT" : "DIRECT DEBIT" }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item>
            <div mat-line>Realex Ref ID</div>
            <div>{{ sales.payment.reference }}</div>
            <button mat-icon-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.reference ">
              <mat-icon class="small-icon">content_copy</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
  
        <table class="minimalistBlack">
          <thead>
            <tr>
              <th>Paid Amount</th>
              <th>
                
                <button class="table-price" mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.amount.toString()">
                  {{ sales.payment.amount | currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
               
                
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><code>Insurer Premium</code></td>
              <td>
                <button class="table-price" mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.offer.cpfCalculation.insurerPremium.toString()">
                  {{ sales.payment.offer.cpfCalculation.insurerPremium| currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td><code>Pricing Result (Fee)</code></td>
              <td>
                <button class="table-price" mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.offer.cpfCalculation.pricingResult.toString()">
                  {{ sales.payment.offer.cpfCalculation.pricingResult| currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td><code>Premium Amount</code></td>
              <td>
                <button class="table-price" mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.premiumAmount.toString()">
                  {{ sales.payment.premiumAmount| currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="!sales.payment.isFullPayment">
              <td><code>Deposit Amount</code></td>
              <td>
                
                <button class="table-price" mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.offer.cpfCalculation.deposit.toString()">
                  {{ sales.payment.offer.cpfCalculation.deposit| currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="!sales.payment.isFullPayment">
              <td><code>Amount Financed</code></td>
              <td>
              
                <button class="table-price"  mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.offer.cpfCalculation.amountFinanced.toString()">
                  {{ sales.payment.offer.cpfCalculation.amountFinanced| currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="!sales.payment.isFullPayment">
              <td><code>Interest</code></td>
              <td>
               
                <button class="table-price" mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.offer.cpfCalculation.interest.toString()">
                  {{ sales.payment.offer.cpfCalculation.interest| currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="!sales.payment.isFullPayment">
              <td><code>Monthly Amount</code></td>
              <td>
               
                 <button class="table-price"  mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.offer.cpfCalculation.monthlyAmount.toString()">
                  {{ sales.payment.offer.cpfCalculation.monthlyAmount| currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="!sales.payment.isFullPayment">
              <td><code>Total Cost</code></td>
              <td>
            
                 <button class="table-price" mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.offer.cpfCalculation.totalCost.toString()">
                  {{ sales.payment.offer.cpfCalculation.totalCost| currency : "EUR" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngIf="!sales.payment.isFullPayment">
              <td><code>Deposit %</code></td>
              <td>
               
                <button class="table-price" mat-button aria-label="content_copy" [cdkCopyToClipboard]="sales.payment.offer.cpfCalculation.depositPercent.toString()">
                  {{ sales.payment.offer.cpfCalculation.depositPercent + "%" }}
                  <mat-icon class="small-icon">content_copy</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
  