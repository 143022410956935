import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalesList } from '../model/HFQ/SalesList.model';
import { environment } from 'src/environments/environment';
import { SaleParameter } from '../model/HFQ/SaleParameter.model';
import { ToCreatePolicy } from '../model/HFQ/ToCreatePolicy.model';
import { Policy } from '../model/HFQ/Policy.model';
import { Sales } from '../model/HFQ/Sales.model';

@Injectable({
  providedIn: 'root'
})
export class HfqService {

  constructor(private http: HttpClient) { }


  getSales(saleParameter: SaleParameter): Observable<SalesList> {
    // var functCode = environment.production ? 'OHyMgV1z9IqhBPqvXITdyMk6KRpn9SXPnR0OSgjNauC8ss4Wy40JMg==':'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==';
  //  var Param: any = {
  //    InitialDate: ReferenceCode,
   // };
  //  const headers = new HttpHeaders()
  //    .set('Content-Type', 'application/json')
   //   .set('x-functions-key', environment.rasfunctCode)
     // .set('Access-Control-Allow-Origin', '*');

    return this.http.post<SalesList>(
      environment.hfqAPI.address + 'api/Sale/GetSales',
      saleParameter
    );
  }
  createPolicy(saleParameter: ToCreatePolicy): Observable<Policy> {
    // var functCode = environment.production ? 'OHyMgV1z9IqhBPqvXITdyMk6KRpn9SXPnR0OSgjNauC8ss4Wy40JMg==':'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==';
  //  var Param: any = {
  //    InitialDate: ReferenceCode,
   // };
  //  const headers = new HttpHeaders()
  //    .set('Content-Type', 'application/json')
   //   .set('x-functions-key', environment.rasfunctCode)
     // .set('Access-Control-Allow-Origin', '*');

    return this.http.post<Policy>(
      environment.hfqAPI.address + 'api/Sale/CreatePolicy',
      saleParameter
    );
  }

  saveSale(sale: Sales): Observable<any>  {
    // var functCode = environment.production ? 'OHyMgV1z9IqhBPqvXITdyMk6KRpn9SXPnR0OSgjNauC8ss4Wy40JMg==':'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==';
  //  var Param: any = {
  //    InitialDate: ReferenceCode,
   // };
  //  const headers = new HttpHeaders()
  //    .set('Content-Type', 'application/json')
   //   .set('x-functions-key', environment.rasfunctCode)
     // .set('Access-Control-Allow-Origin', '*');
     debugger
     return this.http.post(
      environment.hfqAPI.address + 'api/Sale/SaveSale',
      sale
    );
  }
}
