import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sales } from 'src/app/model/RSS/Sales.model';


@Component({
  selector: 'app-rss-discount-details',
  templateUrl: './rss-discount-details.component.html',
  styleUrls: ['./rss-discount-details.component.css']
})
export class RssDiscountDetailsComponent implements OnInit {

  @Input() sales: Sales;
 
   public DamageCover: any;
   public paidAmountDataSource: any;
   public FinancedAmountDataSource: any;
   constructor(
     @Inject(MAT_DIALOG_DATA) public saleParameter: Sales
   ) {
     this.sales = saleParameter;
     this.DamageCover = [
       {
        //  buildingsAccidentalDamageCoverIncluded: saleParameter.buildingsAccidentalDamageCoverIncluded,
        //  contentsAccidentalDamageCoverIncluded: saleParameter.contentsAccidentalDamageCoverIncluded,
       },
     ];
     // debugger;
     this.paidAmountDataSource = [
       {
        //  insurerPremium: saleParameter.payment.displayOxara?.cpfCalculation.insurerPremium,
        //  pricingResult: saleParameter.payment.displayOxara?.cpfCalculation.pricingResult,
         displayAmount: saleParameter.payment.premiumAmount,
 
         isFullPayment: saleParameter.payment.isFullPayment
       },
     ];
     this.FinancedAmountDataSource = [
       {
 
        //  depositPercent: saleParameter.payment.displayOxara?.cpfCalculation.depositPercent,
         depositAmount: saleParameter.payment.depositAmount,
        //  amountFinanced: saleParameter.payment.displayOxara?.cpfCalculation.amountFinanced,
        //  interest: saleParameter.payment.displayOxara?.cpfCalculation.interest,
         monthlyAmount: saleParameter.payment.monthlyAmount,
        //  totalCost: saleParameter.payment.displayOxara?.cpfCalculation.totalCost,
 
         isFullPayment: saleParameter.payment.isFullPayment
       },
     ];
   }
 
   ngOnInit(): void {}
 
   displayedColumns: string[] = [
     'buildingsAccidentalDamageCoverIncluded',
     'contentsAccidentalDamageCoverIncluded'
   ];
 
   paidAmountTableColumns: string[] = [
     'insurerPremium',
     'pricingResult',
     'displayAmount',
   ];
   FinancedAmountTableColumns: string[] = [
     'deposit',
     'amountFinanced',
     'interest',
     'monthlyAmount',
     'totalCost',
     'depositPercent',
   ];

}
