import { Component, Input, OnInit, Inject } from '@angular/core';
import { Sales } from 'src/app/model/sales.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-status-sales',
  templateUrl: './status-sales.component.html',
  styleUrls: ['./status-sales.component.css'],
})
export class StatusSalesComponent implements OnInit {
  @Input() sales: Sales;
  public username: string;
  public selectedStatus: string;

  constructor(
    public dialogRef: MatDialogRef<StatusSalesComponent>,
    @Inject(MAT_DIALOG_DATA) public dataParameter: any,
    private authService: MsalService,
    private data: DataService
  ) {
    this.sales = dataParameter.sale;
    this.username = dataParameter.username;
    this.selectedStatus = dataParameter.status;
  }

  ngOnInit(): void {}

  async updateStatus(selectedStatus) {
    let status: any = {
      idStatusSettings: selectedStatus,
      orderId:
        this.sales.payment.timeStamp + '-' + this.sales.payment.reference,
      username: this.username,
    };

    this.data.updateStatus(status);

    this.dialogRef.close({ selectedStatus: selectedStatus });
  }
}
