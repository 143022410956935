<mat-card class="sales-status-card" >
    <mat-card-title>Paid Amount Breakdown</mat-card-title>
    <mat-divider inset></mat-divider>
    <mat-card-content>
      <div class="logo-insurer-discounts">
        <img
          src="https://cdn.chill.ie/hfq/img/{{ sales.schemeCode }}.png"
          alt="{{ sales.schemeCode }}"
        />
        <span>{{ sales.schemeCode }}</span>
      </div>
      <mat-divider inset></mat-divider>
      <div class="discount-table">
        <table mat-table [dataSource]="DamageCover">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
  
          <!-- Position Column -->
          <ng-container matColumnDef="buildingsAccidentalDamageCoverIncluded">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Buildings Accidental Damage
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.buildingsAccidentalDamageCoverIncluded }}
            </td>
          </ng-container>
  
          <!-- Name Column -->
          <ng-container matColumnDef="contentsAccidentalDamageCoverIncluded">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Contents Accidental Damage
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.contentsAccidentalDamageCoverIncluded  }}
            </td>
          </ng-container>
  
          <!-- Weight Column -->
          <!-- <ng-container matColumnDef="totalInsurerDiscount">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Total Insurer Discount
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.TotalInsurerDiscount | currency : "EUR" }}
            </td>
          </ng-container> -->
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
  
      <div class="paid-amount-container">
        <h3>Paid Amount</h3>
        <h2>{{ sales.payment.amount| currency : "EUR" }}</h2>
  
        <div class="paid-amount-table">
          <table mat-table [dataSource]="paidAmountDataSource">
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
  
            <!-- Position Column -->
            <ng-container matColumnDef="displayAmount">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                Premium Amount
              </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{ element.displayAmount | currency : "EUR" }}
              </td>
            </ng-container>
  
            <!-- Name Column -->
            <ng-container  matColumnDef="depositAmount">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                Deposit Amount
              </th>
              <td mat-cell *matCellDef="let element"  class="text-center">
                <label *ngIf="!element.isFullPayment">{{ element.depositAmount | currency : "EUR" }}</label>
                <label *ngIf="element.isFullPayment">{{ "-" }}</label>
              </td>
            </ng-container>
  
            <!-- Weight Column  -->
            <ng-container  matColumnDef="keyCareFee">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                KeyCare Fee
              </th>
              <td mat-cell  *matCellDef="let element"  class="text-center">
                <label *ngIf="element.isKeyCareSelected">  {{ element.keyCareFee | currency : "EUR" }}</label>
                <label *ngIf="!element.isKeyCareSelected">{{ "-" }}</label>
              </td>
              
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="paidAmountTableColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: paidAmountTableColumns"
            ></tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  