import { Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Filter } from 'src/app/model/filter.model';
import { Sales } from 'src/app/model/sales.model';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { StatusSalesComponent } from 'src/app/component/modal/template/sales/status-sales/status-sales.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IbanClipboardComponent } from 'src/app/component/modal/template/sales/iban-clipboard/iban-clipboard.component';
import { DiscountDetailsComponent } from 'src/app/component/modal/template/sales/discount-details/discount-details.component';
import { ClientCodeUpdateComponent } from 'src/app/component/modal/template/sales/client-code-update/client-code-update.component';
import { SalesFigures } from 'src/app/model/salesFigures.model';
import { Page, PageRequest } from 'src/app/util.ts/Pagination';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { UpdateSalesInfoComponent } from 'src/app/component/modal/template/sales/update-sales-info/update-sales-info.component';
import { DocumentUploadedComponent } from 'src/app/component/modal/template/sales/document-uploaded/document-uploaded.component';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css'],
})
export class DashboardPageComponent implements OnInit {
  user: AccountInfo;

  pageIndex: number = 0;
  pageSize: number = 100;
  length: number;

  disabled: boolean = true;

  public dataSource: Sales[] = [];
  public sales$: Observable<Sales[]>;
  public figures: SalesFigures;
  public dateToday: Date = new Date();
  public busy: boolean = false;
  public searchedText: string = '';

  startDate: any;
  endDate: any;

  pageEvent: PageEvent;
  sortEvent: Sort;

  page: Page<Sales> = new Page([], 0);

  public range = new UntypedFormGroup({
    start: new UntypedFormControl(new Date()),
    end: new UntypedFormControl(new Date()),
  });

  constructor(
    private data: DataService,
    private authService: MsalService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private generalService: GeneralService
  ) {}

  //#region "Table Settings"

  displayedColumns: string[] = [
    'ras',
    'status',
    'clientCode',
    'realexReferenceID',
    'customerName',
    'dateSold',
    'insurer',
    'price',
    'paidAmount',
    'keycare',
    'iban',
    'documents',
    'inceptionDate',
  ];

  //#endregion

  //#region "Functions"

  downloadRasFile(quoteQueryGuid: string,transactionId: string) {
    this.generalService.getRasFileByBrokerId(quoteQueryGuid,transactionId).subscribe((res) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(res);
      a.href = objectUrl;
      a.download = `${transactionId}.ras`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  resetPaging() {
    this.pageIndex = 0;
    this.pageSize = this.pageEvent ? this.pageEvent.pageSize : 100;
    if (this.pageEvent !== undefined) {
      this.pageEvent.pageIndex = 0;
    }
  }

  listSales() {
    let queryAditional;

    let filter;

    if (
      this.range.controls['start'].value === null ||
      this.range.controls['end'].value === null
    ) {
    } else if (
      this.range.controls['start'].value !== null &&
      this.range.controls['end'].value !== null
    ) {
      this.page.content = [];
      this.busy = true;
      filter = new Filter(
        this.datepipe.transform(
          this.range.controls['start'].value,
          'yyyy-MM-dd'
        ),
        this.datepipe.transform(this.range.controls['end'].value, 'yyyy-MM-dd'),
        this.searchedText
      );

      this.startDate = this.datepipe.transform(filter.startDate, 'dd/MM/yyyy');

      this.endDate = this.datepipe.transform(filter.endDate, 'dd/MM/yyyy');

      this.data
        .getSales(
          filter,
          new PageRequest(
            {
              pageNumber: this.pageEvent ? this.pageEvent.pageIndex : 0,
              pageSize: this.pageEvent ? this.pageEvent.pageSize : 100,
            },
            {
              property: this.sortEvent ? this.sortEvent.active : 'id',
              direction: this.sortEvent ? this.sortEvent.direction : 'asc',
            },
            queryAditional
          )
        )
        .pipe(take(1))
        .subscribe(
          (page) => {
            this.page = page;
            this.busy = false;
            var timezoneOffset = new Date().getTimezoneOffset() * -1;
            page.content.forEach((element) => {
              var d = new Date(element.payment.dateUpdated);
              element.payment.dateUpdated = new Date(
                d.setMinutes(d.getMinutes() + timezoneOffset)
              );
            });

            this.dataSource = page.content;
          },
          (error) => {
            this.page = new Page([], 0);
            this.busy = false;
          }
        );
      this.getSalesFigures();
    }
  }

  getSalesFigures() {
    let filter = new Filter(
      this.datepipe.transform(this.range.controls['start'].value, 'yyyy-MM-dd'),
      this.datepipe.transform(this.range.controls['end'].value, 'yyyy-MM-dd'),
      this.searchedText
    );

    this.data.getSalesFigures(filter).subscribe((res) => {
      this.figures = res;
      console.log(this.figures);
    });
  }

  //#endregion

  //#region "Events"

  ngOnInit(): void {
    console.log(this.authService.instance.getAllAccounts()[0]);
    this.getSalesFigures();
    this.listSales();
    this.user = this.authService.instance.getAllAccounts()[0];
  }

  dateRangeChanged() {
    this.resetPaging();
    //this.searchedText = '';
    this.listSales();
  }

  pageChanged() {
    if (this.pageEvent.pageSize !== this.pageSize) this.pageEvent.pageIndex = 0;

    this.pageIndex = this.pageEvent ? this.pageEvent.pageIndex : 0;
    this.pageSize = this.pageEvent ? this.pageEvent.pageSize : 100;

    this.listSales();
  }

  sortChanged() {
    this.resetPaging();

    this.listSales();
  }

  searchChanged() {
    this.resetPaging();
    // if (this.searchedText !== '') {
    this.listSales();
    // }
  }

  getTodaySales() {
    this.range = new UntypedFormGroup({
      start: new UntypedFormControl(new Date()),
      end: new UntypedFormControl(new Date()),
    });

    this.listSales();
  }

  //#endregion

  //#region "Dialog"

  openIbanDialog(sale) {
    const dialogRef = this.dialog.open(IbanClipboardComponent, {
      width: '100%',
      maxWidth: '500px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '300',
      data: sale.iban,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDocumentDialog(sale) {
    const dialogRef = this.dialog.open(DocumentUploadedComponent, {
      width: '100%',
      maxWidth: '1000px',
      height: 'auto',
      hasBackdrop: true,
      minHeight: '600px',
      data: {
        documents: sale.documents,
        id: sale.quoteResponse.quoteQueryGuid,
        agentEmail: this.user?.username,
        reference: sale.payment.reference,
        clientCode: sale.clientCode,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openStatusUpdateDialog(sale) {
    const dialogRef = this.dialog.open(StatusSalesComponent, {
      width: '100%',
      maxWidth: '700px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '400',
      data: { sale, username: this.user.username },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result.selectedStatus !== undefined)
        sale.status = result.selectedStatus;
    });
  }

  openDiscountDetailsDialog(sale) {
    this.dialog.open(DiscountDetailsComponent, {
      width: '100%',
      maxWidth: '700px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '500',
      data: sale,
    });
  }

  openClientCodeDialog(sale) {
    const clientCodeDialog = this.dialog.open(ClientCodeUpdateComponent, {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '300',
      data: sale,
    });

    clientCodeDialog.afterClosed().subscribe((result) => {
      if (result !== undefined) sale.clientCode = result.clientCode;
    });
  }

  openUpdateSaleDialog(sale) {
    if (sale.clientCode) {
      const dialogRef = this.dialog.open(UpdateSalesInfoComponent, {
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        hasBackdrop: true,
        maxHeight: '500',
        data: sale,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          sale.payment.insurerId = result.status.insurerId;
          sale.quoteSummaries.displayedPremium = result.status.amount;
        }
      });
    }
  }

  //#endregion
}
