import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardPageComponent } from './page/dashboard-page/dashboard-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NavbarComponent } from './component/navbar/navbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';

import { MatTableModule } from '@angular/material/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { StatusSalesComponent } from './component/modal/template/sales/status-sales/status-sales.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IbanClipboardComponent } from './component/modal/template/sales/iban-clipboard/iban-clipboard.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DiscountDetailsComponent } from './component/modal/template/sales/discount-details/discount-details.component';
import { HFQDiscountDetailsComponent } from './component/modal/template/sales/hfq-discount-details/hfq-discount-details.component';

import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { ClientCodeUpdateComponent } from './component/modal/template/sales/client-code-update/client-code-update.component';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { UpdateSalesInfoComponent } from './component/modal/template/sales/update-sales-info/update-sales-info.component';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { AzureActiveDirectoryService } from './azure-active-directory-service';
import { MatSelectModule } from '@angular/material/select';
import { environment } from 'src/environments/environment';
import { DocumentUploadedComponent } from './component/modal/template/sales/document-uploaded/document-uploaded.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HfqDashboardPageComponent } from './page/hfq-dashboard-page/hfq-dashboard-page.component';
import { BrowserModule } from '@angular/platform-browser';
import { RssDashboardPageComponent } from './page/rss-dashboard-page/rss-dashboard-page.component';
import { RssDocumentUploadedComponent } from './component/rss-document-uploaded/rss-document-uploaded.component';
import { RssDiscountDetailsComponent } from './component/rss-discount-details/rss-discount-details.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > 1;
  export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
  }
  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
  
        clientId: environment.clientId,
        redirectUri: environment.postLogoutUrl,
        authority: `https://login.microsoftonline.com/${environment.tenantId}`,
  
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Error,
          piiLoggingEnabled: true
        }
      }
    });
  }
  
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  
    const protectedResourceMap = new Map<string, Array<string>>([
      [
        environment.apiManagement.address,
        environment.apiManagement.scopes,
      ],
      ['https://graph.microsoft.com/v1.0/me', ['user.read']],
      ['https://management.azure.com/', ['https://management.azure.com/user_impersonation']]
    ])
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }
  
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      },
      loginFailedRoute: '/login-failed'
    };
  }
@NgModule({
  declarations: [
    AppComponent,
    DashboardPageComponent,
    NavbarComponent,
    StatusSalesComponent,
    IbanClipboardComponent,
    DiscountDetailsComponent,
    ClientCodeUpdateComponent,
    UpdateSalesInfoComponent,
    DocumentUploadedComponent,
    HfqDashboardPageComponent,
    HFQDiscountDetailsComponent,
    RssDashboardPageComponent,
    RssDocumentUploadedComponent,
    RssDiscountDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatDividerModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatTooltipModule,
    MatRippleModule,
    MatMenuModule,
    MatSelectModule,
    MatExpansionModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    PdfViewerModule,
    MatListModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    AzureActiveDirectoryService,
    DatePipe,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
