<div class="modal-container">
  <div class="document-container">
    <mat-card class="document-card">
      <mat-card-title>Uploaded Documents</mat-card-title>
      <mat-card-subtitle>You can preview the doc by clicking on the buttons
      </mat-card-subtitle>
      <mat-divider inset class="mb-20"></mat-divider>
      <mat-card-content>
        <div>
          <div class="line" *ngIf="hasD1NCD">
            <span>NCD</span>
            <div class="iconGroup">
              <mat-icon matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" aria-hidden="false"
                matTooltip="Download NCD" matTooltipPosition="left"
                (click)="previewFile('D1NCD')">assignment_ind</mat-icon>
            </div>
          </div>
          <mat-divider inset *ngIf="hasD1NCD"></mat-divider>

          <div class="line" *ngIf="hasD1LicenceFront === true && hasD1LicenceBack === true">
            <span>First Licence</span>
            <div class="iconGroup">
              <mat-icon *ngIf="hasD1LicenceFront" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Front Licence" matTooltipPosition="left"
                (click)="previewFile('D1LicenceFront')">assignment_ind</mat-icon>
              <mat-icon *ngIf="hasD1LicenceBack" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Back Licence" matTooltipPosition="right"
                (click)="previewFile('D1LicenceBack')">assignment</mat-icon>
            </div>
          </div>
          <mat-divider inset *ngIf="hasD1LicenceFront === true && hasD1LicenceBack === true"></mat-divider>
          <div class="line" *ngIf="hasD2LicenceFront === true && hasD2LicenceBack === true">
            <span>Second Licence</span>
            <div class="iconGroup">
              <mat-icon *ngIf="hasD2LicenceFront" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Front Licence" matTooltipPosition="left"
                (click)="previewFile('D2LicenceFront')">assignment_ind</mat-icon>
              <mat-icon *ngIf="hasD2LicenceBack" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Back Licence" matTooltipPosition="right"
                (click)="previewFile('D2LicenceBack')">assignment</mat-icon>
            </div>
          </div>
          <mat-divider inset *ngIf="hasD2LicenceFront === true && hasD2LicenceBack === true"></mat-divider>
          <div class="line" *ngIf="hasD3LicenceFront === true && hasD3LicenceBack === true">
            <span>Third Licence</span>
            <div class="iconGroup">
              <mat-icon *ngIf="hasD3LicenceFront" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Front Licence" matTooltipPosition="left"
                (click)="previewFile('D3LicenceFront')">assignment_ind</mat-icon>
              <mat-icon *ngIf="hasD3LicenceBack" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Back Licence" matTooltipPosition="right"
                (click)="previewFile('D3LicenceBack')">assignment</mat-icon>
            </div>
          </div>
          <mat-divider inset *ngIf="hasD3LicenceFront === true && hasD3LicenceBack === true"></mat-divider>
          <div class="line" *ngIf="hasD4LicenceFront === true && hasD4LicenceBack === true">
            <span>Fourth Licence</span>
            <div class="iconGroup">
              <mat-icon *ngIf="hasD4LicenceFront" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Front Licence" matTooltipPosition="left"
                (click)="previewFile('D4LicenceFront')">assignment_ind</mat-icon>
              <mat-icon *ngIf="hasD1LicenceBack" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Back Licence" matTooltipPosition="right"
                (click)="previewFile('D4LicenceBack')">assignment</mat-icon>
            </div>
          </div>
          <mat-divider inset *ngIf="hasD4LicenceFront === true && hasD4LicenceBack === true"></mat-divider>
          <div class="line" *ngIf="hasD5LicenceFront === true && hasD5LicenceBack === true">
            <span>Fifth Licence</span>
            <div class="iconGroup">
              <mat-icon *ngIf="hasD5LicenceFront" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Front Licence" matTooltipPosition="left"
                (click)="previewFile('D5LicenceFront')">assignment_ind</mat-icon>
              <mat-icon *ngIf="hasD5LicenceBack" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Back Licence" matTooltipPosition="right"
                (click)="previewFile('D5LicenceBack')">assignment</mat-icon>
            </div>
          </div>
          <mat-divider inset *ngIf="hasD6LicenceFront === true && hasD6LicenceBack === true"></mat-divider>

          <div class="line" *ngIf="hasD6LicenceFront === true && hasD6LicenceBack === true">
            <span>Sixth Licence</span>
            <div class="iconGroup">
              <mat-icon *ngIf="hasD6LicenceFront" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Front Licence" matTooltipPosition="left"
                (click)="previewFile('D6LicenceFront')">assignment_ind</mat-icon>
              <mat-icon *ngIf="hasD6LicenceBack" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true"
                aria-hidden="false" matTooltip="Download Back Licence" matTooltipPosition="right"
                (click)="previewFile('D6LicenceBack')">assignment</mat-icon>
            </div>
          </div>
          <mat-divider inset *ngIf="hasD6LicenceFront === true && hasD6LicenceBack === true"></mat-divider>
          <div *ngFor="let Data of extractedData">
            <div class="line">
              <span *ngIf="Data.driverNumber === 0">Second Licence</span>
              <span *ngIf="Data.driverNumber === 1">first Licence</span>
              <span *ngIf="Data.driverNumber === 2">Second Licence</span>
              <span *ngIf="Data.driverNumber === 3">Third Licence</span>
              <span *ngIf="Data.driverNumber === 4">Fourth Licence</span>
              <span *ngIf="Data.driverNumber === 5">Fifth Licence</span>
              <span *ngIf="Data.driverNumber === 6">Sixth Licence</span>

              <div class="iconGroup" *ngIf="Data.typeOfDoc === 'file'">
                <span *ngFor="let file of Data.filePath">
                  <mat-icon *ngIf="file.side === 'front'" matRipple [matRippleCentered]="true"
                    [matRippleUnbounded]="true" aria-hidden="false" matTooltip="Download Front Licence"
                    matTooltipPosition="left"
                    (click)="previewFilePath(file.filePath,file.side,Data.driverName)">assignment_ind</mat-icon>
                  <mat-icon *ngIf="file.side === 'back'" matRipple [matRippleCentered]="true"
                    [matRippleUnbounded]="true" aria-hidden="false" matTooltip="Download Back Licence"
                    matTooltipPosition="right"
                    (click)="previewFilePath(file.filePath,file.side,Data.driverName)">assignment</mat-icon>
                </span>
              </div>
              
            </div>
            <mat-divider inset></mat-divider>
          </div>
        </div>

        <div>
          <mat-divider inset></mat-divider>
          <div class="line">
            <span *ngIf="clientCode">NB Pack</span>
            <span *ngIf="!clientCode">NB Pack download is disabled, to <br />enable it please insert
              first client code.</span>
            <div>
              <button mat-raised-button matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" color="accent"
                [class.spinner]="loadingDownloadNBPack" [disabled]="loadingDownloadNBPack === true || !clientCode"
                (click)="downloadAllFiles()">
                  
                <mat-icon aria-hidden="false" matTooltip="Download NB Pack"
                  matTooltipPosition="right">download</mat-icon>
            
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="preview-container">
    <mat-card class="document-card">
      <mat-card-title>Preview</mat-card-title>
      <!-- <mat-card-subtitle>&nbsp;</mat-card-subtitle> -->
      <mat-divider inset class="mb-20"></mat-divider>
      <mat-card-content>
        <div class="preview-category-name" *ngIf="!loadingPreview">
          {{ previewCategory }}
        </div>
        <div>
          <div *ngIf="loadingPreview" class="loading-panel">
            <mat-spinner></mat-spinner>
          </div>

          <div *ngIf="!loadingPreview && fileType !== 'application/pdf'">
            <img [src]="previewImage" *ngIf="previewImage" width="100%" height="100%" alt=""
              class="image-preview mat-elevation-z8" [ngClass]="{
                'rotate-left-360': previewRotate == -4,
                'rotate-left-270': previewRotate == -3,
                'rotate-left-180': previewRotate == -2,
                'rotate-left-90': previewRotate == -1,
                'rotate-0': previewRotate == 0,
                'rotate-right-90': previewRotate == 1,
                'rotate-right-180': previewRotate == 2,
                'rotate-right-270': previewRotate == 3,
                'rotate-right-360': previewRotate == 4
              }" />
          </div>

          <div *ngIf="!loadingPreview && fileType === 'application/pdf'">
            <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" [rotation]="pdfRotateAngle"
              style="width: 540px; height: 400px" class="pdf-preview mat-elevation-z8"></pdf-viewer>
          </div>
        </div>
        <div *ngIf="previewCategory && loadingPreview === false">
          <button matTooltip="Rotate to Left" matTooltipPosition="left" mat-raised-button color="accent"
            (click)="rotateToLeft()" [disabled]="previewRotate === -4">
            <mat-icon>rotate_left</mat-icon>
          </button>
        
          <button mat-raised-button color="accent" [class.spinner]="loadingDownload" [disabled]="loadingDownload"
            (click)="PreviewFrom === 'onboarding'?downloadFile(previewCategory):downloadFileValidation(ImageOpen) ">
            Download
            <mat-icon>download</mat-icon>
          </button>
          &nbsp;
          <button matTooltip="Rotate to Right" matTooltipPosition="right" mat-raised-button color="accent"
            (click)="rotateToRight()" [disabled]="previewRotate === 4">
            <mat-icon>rotate_right</mat-icon>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>