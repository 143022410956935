import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Insurer } from '../model/insurer.model';
import { Sales } from '../model/sales.model';
import { SalesFigures } from '../model/salesFigures.model';
import { Page, QueryBuilder } from '../util.ts/Pagination';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  getSalesFigures(rangeDate): Observable<SalesFigures> {
    return this.http.post<SalesFigures>(
      `${environment.baseAddressAPI}/api/GetSalesFigures?code=${environment.code}`,
      rangeDate
    );
  }

  getAllInsurers(): Observable<Insurer[]> {
    return this.http.get<Insurer[]>(
      `${environment.baseAddressAPI}/api/GetAllInsurers?code=${environment.code}`
    );
  }

  getSales(rangeDate, queryBuilder: QueryBuilder): Observable<Page<Sales>> {
    return this.http
      .post<Sales[]>(
        `${
          environment.baseAddressAPI
        }/api/searchSalesWCC?${queryBuilder.buildQueryString()}&code=${
          environment.code
        }`,
        rangeDate,
        { observe: 'response' }
      )
      .pipe(map((response) => <Page<Sales>>Page.fromResponse(response)));
  }

  updateStatus(status) {
    return this.http
      .post(
        `${environment.baseAddressAPI}/api/AddSaleStatus?code=${environment.code}`,
        status
      )
      .toPromise();
  }

  updateClientCode(status) {
    return this.http
      .post(
        `${environment.baseAddressAPI}/api/AddClientCode?code=${environment.code}`,
        status
      )
      .toPromise();
  }

  UpdateRasStatus(status) {
    return this.http
      .post(
        `${environment.baseAddressAPI}/api/SaveUpdate?code=${environment.code}`,
        status
      )
      .toPromise();
  }
}
