<mat-card class="sales-status-card">
  <mat-card-title>Update Sale Info</mat-card-title>
  <mat-divider inset></mat-divider>
  <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
    <mat-card-content>
      <div class="client-detail">
        <div class="client-name-info mb-20">
          <mat-icon aria-hidden="false"> account_box </mat-icon>
          {{
            sales.quoteQueries.proposerForeName +
              " " +
              sales.quoteQueries.proposerSurName
          }}
        </div>
        <div class="client-code-info">
          <mat-icon aria-hidden="false"> tag </mat-icon>
          {{ sales.clientCode }}
        </div>
      </div>
      <mat-divider inset class="mb-10"></mat-divider>

      <mat-form-field appearance="standard">
        <mat-label>Select a new Insurer</mat-label>
        <mat-select formControlName="insurer">
          <mat-option *ngFor="let ins of insurerList" [value]="ins.code">
            {{ ins.insurerLegalName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard" floatLabel="always">
        <mat-label>Policy Price</mat-label>
        <input
          matInput
          type="number"
          class="example-right-align"
          placeholder="0"
          formControlName="price"
        />
        <span matPrefix>€&nbsp;</span>
        <!-- <span matSuffix>.00</span> -->
      </mat-form-field>
    </mat-card-content>
    <mat-divider inset></mat-divider>
    <mat-card-actions>
      <div class="text-right">
        <button (click)="updateSaleDetail()" mat-button>SAVE</button>
      </div>
    </mat-card-actions>
  </form>
</mat-card>
