import { GeneralService } from 'src/app/services/general.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AzureActiveDirectoryService } from './azure-active-directory-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  isUserLoggedIn: boolean = false;
  public user: any = null;
  public urlGriad: string = 'MFQ';
  private readonly _destroy = new Subject<void>();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadCastService: MsalBroadcastService,
    private authService: MsalService,
    private azureADService: AzureActiveDirectoryService,
    private generalService: GeneralService,
    private router: Router
  ) {}
  ngOnInit(): void {
   
    this.router.events.subscribe((event: any) => {
      
      if(event.snapshot?.routeConfig?.path)
        this.urlGriad = event.snapshot.routeConfig.path;

   
    });
    this.generalService.getMe().subscribe(namespaces => {
      console.log(namespaces);
      
    })
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadCastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          // this.setLoginDisplay();
        }
      });
  }
    // this.msalBroadCastService.inProgress$
    //   .pipe(
    //     filter(
    //       (interactionStatus: InteractionStatus) =>
    //         interactionStatus == InteractionStatus.None
    //     ),
    //     takeUntil(this._destroy)
    //   )
    //   .subscribe((x) => {
    //     this.user = this.authService.instance.getAllAccounts()[0];
    //     this.isUserLoggedIn =
    //       this.authService.instance.getAllAccounts().length > 0;

    //     this.azureADService.isUserLoggedIn.next(this.isUserLoggedIn);
    //   });
  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.postLogoutUrl,
    });
  }
}
