import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private http: HttpClient) {}

  getRasFileByBrokerId(quoteQueryGuid: string, transactionId: string): Observable<any> {
    const headers = new HttpHeaders()
    .set('Ocp-Apim-Subscription-Key', environment.apiManagement.subscriptionKey);
    return this.http.get(
      environment.apiManagement.address +
        'v1/getRasFileByBrokerId?quoteQueryGuid=' +
        quoteQueryGuid +
        '&QuoteResponseID='+ transactionId,
      {
        responseType: 'blob',
        headers: headers,
      }
    );
  }

  getMe(): Observable<any> {

    return this.http.get<any>('https://graph.microsoft.com/v1.0/me')

  }
}
