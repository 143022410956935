import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { StatusSalesComponent } from 'src/app/component/modal/template/sales/status-sales/status-sales.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IbanClipboardComponent } from 'src/app/component/modal/template/sales/iban-clipboard/iban-clipboard.component';
import { DiscountDetailsComponent } from 'src/app/component/modal/template/sales/discount-details/discount-details.component';
import { ClientCodeUpdateComponent } from 'src/app/component/modal/template/sales/client-code-update/client-code-update.component';
import { SalesFigures } from 'src/app/model/salesFigures.model';
import { Page, PageRequest } from 'src/app/util.ts/Pagination';
import { UpdateSalesInfoComponent } from 'src/app/component/modal/template/sales/update-sales-info/update-sales-info.component';
import { DocumentUploadedComponent } from 'src/app/component/modal/template/sales/document-uploaded/document-uploaded.component';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { GeneralService } from 'src/app/services/general.service';
import { HfqService } from 'src/app/services/hfq.service';

import { SalesList } from 'src/app/model/HFQ/SalesList.model';
import { Sales } from 'src/app/model/HFQ/Sales.model';
import { ToCreatePolicy } from 'src/app/model/HFQ/ToCreatePolicy.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { HFQDiscountDetailsComponent } from 'src/app/component/modal/template/sales/hfq-discount-details/hfq-discount-details.component';
import { SaleParameter } from 'src/app/model/RSS/SaleParameter.model';

@Component({
  selector: 'app-hfq-dashboard-page',
  templateUrl: './hfq-dashboard-page.component.html',
  styleUrls: ['./hfq-dashboard-page.component.css'],
  
})
export class HfqDashboardPageComponent implements OnInit, AfterViewInit {
  user: AccountInfo;

  pageIndex: number = 0;
  pageSize: number = 100;
  length: number;

  disabled: boolean = true;
  public salesTotal: number = 0;
  public dataSource = new MatTableDataSource<Sales>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public sales$: Observable<Sales[]>;
  //public figures: SalesFigures;
  public dateToday: Date = new Date();
  public busy: boolean = false;
  public searchedText: string = '';

  startDate: any;
  endDate: any;

  // pageEvent: PageEvent;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public range = new UntypedFormGroup({
    start: new UntypedFormControl(new Date()),
    end: new UntypedFormControl(new Date()),
  });

  constructor(
    private hfqService: HfqService,
    private authService: MsalService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
  ) { }

  //#region "Table Settings"

  displayedColumns: string[] = [
    // 'ras',
    'status',
    'clientCode',
    'realexReferenceID',
    'customerName',
    'dateSold',
    'insurer',
    'price',
    'paidAmount',
    'keycare',
    'iban',
    'documents',
    'inceptionDate',
  ];

  //#endregion

  //#region "Functions"

  saveSale(sales: Sales) {
    this.hfqService.saveSale(sales).subscribe({
      next: (res) => {
        console.log("Sale saved")
      },
      error: (error) => {
        console.log("error " + error)
      }
    });

  }

  createPolicy(sales: Sales) {
    console.log(sales);
    let createPolicy = new ToCreatePolicy(sales.cacheId, sales.temporaryQuoteId);
    sales.policyCodeBusy = true;
    this.hfqService.createPolicy(createPolicy).subscribe({
      next: (res) => {
        sales.policyCode = res.policyCode;
        this.saveSale(sales);
        sales.policyCodeBusy = false;

      },
      error: (error) => {
        sales.policyCodeBusy = false;

      }
    })

    // this.generalService.getRasFileByBrokerId(quoteQueryGuid,transactionId).subscribe((res) => {
    //   const a = document.createElement('a');
    //   const objectUrl = URL.createObjectURL(res);
    //   a.href = objectUrl;
    //   a.download = `${transactionId}.ras`;
    //   a.click();
    //   URL.revokeObjectURL(objectUrl);
    // });
  }

  // resetPaging() {
  //   this.pageIndex = 0;
  //   this.pageSize = this.pageEvent ? this.pageEvent.pageSize : 100;
  //   if (this.pageEvent !== undefined) {
  //     this.pageEvent.pageIndex = 0;
  //   }
  // }
  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    debugger
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dateSold':
          return this.compare(a.payment.timeCreated, b.payment.timeCreated, isAsc);
        case 'customerName':
          return this.compare(a.payer.name, b.payer.name, isAsc);
        case 'price':
           return this.compare(a.payment.amount, b.payment.amount, isAsc);
         case 'inceptionDate':
          return this.compare(a.payment.policyStartDate, b.payment.policyStartDate, isAsc);
        // case 'protein':
        //   return compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });
  }
 compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  listSales() {
    let queryAditional;

    let saleParameter;
    if (
      this.range.controls['start'].value === null ||
      this.range.controls['end'].value === null
    ) {
    } else if (
      this.range.controls['start'].value !== null &&
      this.range.controls['end'].value !== null
    ) {
      this.dataSource.data = [];
      this.busy = true;
      saleParameter = new SaleParameter(
        this.datepipe.transform(
          this.range.controls['start'].value,
          'yyyy-MM-dd'
        ),
        this.datepipe.transform(this.range.controls['end'].value, 'yyyy-MM-dd'),
        this.searchedText,
       '01'
      );

      this.startDate = this.datepipe.transform(this.range.controls['start'].value, 'dd/MM/yyyy');

      this.endDate = this.datepipe.transform(this.range.controls['end'].value, 'dd/MM/yyyy');

      this.hfqService
        .getSales(
          saleParameter,
        )
        .pipe(take(1))
        .subscribe({
          next: (page) => {
            this.busy = false;
            this.salesTotal = page.saleTotal;
     
            this.dataSource.data = page.salesList;
          },
          error: (error) => {
            this.busy = false;
          }
        });
    }
  }



  //#endregion

  //#region "Events"

  ngOnInit(): void {
    console.log(this.authService.instance.getAllAccounts()[0]);
    this.listSales();
    this.user = this.authService.instance.getAllAccounts()[0];
  }

  dateRangeChanged() {
    // this.resetPaging();
    //this.searchedText = '';
    this.listSales();
  }

  // pageChanged() {
  //   if (this.pageEvent.pageSize !== this.pageSize) this.pageEvent.pageIndex = 0;

  //   this.pageIndex = this.pageEvent ? this.pageEvent.pageIndex : 0;
  //   this.pageSize = this.pageEvent ? this.pageEvent.pageSize : 100;

  //   this.listSales();
  // }

  // sortChanged() {
  //   // this.resetPaging();

  //   this.listSales();
  // }

  searchChanged() {
    // this.resetPaging();
    // if (this.searchedText !== '') {
    this.listSales();
    // }
  }

  getTodaySales() {
    this.range = new UntypedFormGroup({
      start: new UntypedFormControl(new Date()),
      end: new UntypedFormControl(new Date()),
    });

    this.listSales();
  }

  //#endregion

  //#region "Dialog"

  openIbanDialog(sale) {
    const dialogRef = this.dialog.open(IbanClipboardComponent, {
      width: '100%',
      maxWidth: '700px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '300',
      data: sale.iban,
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  openDocumentDialog(sale) {
    const dialogRef = this.dialog.open(DocumentUploadedComponent, {
      width: '100%',
      maxWidth: '1000px',
      height: 'auto',
      hasBackdrop: true,
      minHeight: '600px',
      data: {
        documents: sale.documents,
        id: sale.quoteResponse.quoteQueryGuid,
        agentEmail: this.user?.username,
        reference: sale.payment.reference,
        clientCode: sale.clientCode,
      },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  openStatusUpdateDialog(sale) {
    const dialogRef = this.dialog.open(StatusSalesComponent, {
      width: '100%',
      maxWidth: '700px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '400',
      data: { sale, username: this.user.username },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result.selectedStatus !== undefined) {
        sale.status = result.selectedStatus;
        this.saveSale(sale);
      }
    });
  }

  openDiscountDetailsDialog(sale) {
    this.dialog.open(HFQDiscountDetailsComponent, {
      width: '100%',
      maxWidth: '1000px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '1000px',
      data: sale,
    });
  }

  openClientCodeDialog(sale) {
    const clientCodeDialog = this.dialog.open(ClientCodeUpdateComponent, {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '300',
      data: sale,
    });

    clientCodeDialog.afterClosed().subscribe((result) => {
      if (result !== undefined) sale.clientCode = result.clientCode;
    });
  }

  openUpdateSaleDialog(sale) {
    if (sale.clientCode) {
      const dialogRef = this.dialog.open(UpdateSalesInfoComponent, {
        width: '100%',
        maxWidth: '500px',
        height: 'auto',
        hasBackdrop: true,
        maxHeight: '500',
        data: sale,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          sale.payment.insurerId = result.status.insurerId;
          sale.quoteSummaries.displayedPremium = result.status.amount;
        }
      });
    }
  }

  //#endregion
}

