import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-iban-clipboard',
  templateUrl: './iban-clipboard.component.html',
  styleUrls: ['./iban-clipboard.component.css'],
})
export class IbanClipboardComponent {
  @Input() iban: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private clipboard: Clipboard
  ) {
    this.iban = data;
    this.clipboard.copy(this.iban);
  }
}
